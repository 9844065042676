import { useEffect, useMemo, useState } from 'react';
import styles from './styles.module.scss';
import { DiscoverCard, SecondaryButton, ContractDetailCard } from 'components';
import { useParams } from 'react-router';
import { ContractType } from 'types/user.type';
import UserService from 'services/user.service';
import { delayNavigate, formatDateTime, formatPrice, isSuccessCode, isVN } from 'utils';
import { E_CONTRACT_STATUS, E_PAYMENT_TYPE } from 'enum';
import { useTranslation } from 'react-i18next';
import { keyBy } from 'lodash';

type Props = {};

export default function ContractDetailContainer({}: Props) {
  const { t } = useTranslation();
  const userService = new UserService();
  const { contractId } = useParams();
  const [contract, setContract] = useState<ContractType>();
  const [isLoading, setIsLoading] = useState(false);

  const getContractDetail = async () => {
    setIsLoading(true);
    const response = await userService.getContractDetail(String(contractId));
    const { data, code } = response;

    if (isSuccessCode(code)) {
      setContract(data);
    }

    delayNavigate(() => setIsLoading(false));
  };

  const joiningFeeInfo = useMemo(() => {
    if (contract) {
      const transactionKeyBy = keyBy(contract.contractTransactions, 'paymentType');
      const joiningFeeTransaction = transactionKeyBy[E_PAYMENT_TYPE.JOINING_FEE];
      if (!joiningFeeTransaction)
        return {
          transaction: 0,
          payment: 0,
        };

      return {
        transaction: Number(joiningFeeTransaction.transactionAmount),
        payment: Number(joiningFeeTransaction.paymentTotal),
      };
    }

    return {
      transaction: 0,
      payment: 0,
    };
  }, [contract]);

  const membershipFeInfo = useMemo(() => {
    if (contract) {
      const transactionKeyBy = keyBy(contract.contractTransactions, 'paymentType');
      const membershipFeeTransaction = transactionKeyBy[E_PAYMENT_TYPE.MEMBERSHIP_FEE];
      if (!membershipFeeTransaction)
        return {
          transaction: 0,
          payment: 0,
        };

      return {
        transaction: Number(membershipFeeTransaction.transactionAmount),
        payment: Number(membershipFeeTransaction.paymentTotal),
      };
    }

    return {
      transaction: 0,
      payment: 0,
    };
  }, [contract]);

  const membershipTransaction = useMemo(() => {
    if (contract) {
      const transactionKeyBy = keyBy(contract.contractTransactions, 'paymentType');
      const membershipFeeTransaction = transactionKeyBy[E_PAYMENT_TYPE.MEMBERSHIP_FEE];

      return membershipFeeTransaction;
    }

    return null;
  }, [contract]);

  const discountAmount = useMemo(() => {
    const discountJoiningFee = joiningFeeInfo.payment - joiningFeeInfo.transaction;
    const discountMembership = membershipFeInfo.payment - membershipFeInfo.transaction;

    return discountJoiningFee + discountMembership;
  }, [joiningFeeInfo, membershipFeInfo]);

  useEffect(() => {
    getContractDetail();
  }, []);

  return (
    <div className={styles.wrapper}>
      <div className={styles.transactionCard}>
        <ContractDetailCard
          isSkeleton={isLoading}
          status={contract?.status as E_CONTRACT_STATUS}
          clubName={
            contract?.paymentPlan?.isBlackCard
              ? t('title.all-club')
              : isVN()
              ? contract?.club?.nameVi
              : contract?.club?.nameEn
          }
          membershipName={isVN() ? contract?.paymentPlan?.nameVi : contract?.paymentPlan?.nameEn}
          membershipFee={membershipFeInfo.payment}
          duration={`${contract?.paymentPlan?.duration?.value} ${t(
            `title.${contract?.paymentPlan?.duration?.unit}`,
          )}`}
          startupFee={
            joiningFeeInfo.payment === 0
              ? t('title.free')
              : formatPrice(joiningFeeInfo.payment || 0)
          }
          promoDiscount={discountAmount}
          referralInfo={
            contract?.referral &&
            `+${contract?.referral?.referralDuration?.value}
            ${t(`title.${contract?.referral?.referralDuration?.unit}`)}`
          }
          paymentDate={
            contract?.status !== E_CONTRACT_STATUS.WAIT_FOR_PAYMENT
              ? formatDateTime(membershipTransaction?.purchaseDate)
              : undefined
          }
          transactionId={
            contract?.status !== E_CONTRACT_STATUS.WAIT_FOR_PAYMENT
              ? membershipTransaction?.contractTransactionPayment.orderRef
              : undefined
          }
          totalPayment={membershipFeInfo.transaction + joiningFeeInfo.transaction}
        />
      </div>
      <div className={styles.description}>
        {contract?.status === E_CONTRACT_STATUS.WAIT_FOR_PAYMENT ? (
          <>
            <p className="color-black font-bold font-lg">{t('title.proceed-payment-at-counter')}</p>
            <p
              className="color-black font-light font-sm"
              style={{
                whiteSpace: 'pre-wrap',
                marginTop: '12px',
                lineHeight: 'normal',
              }}
            >
              {t('paragraph.pr39')}
            </p>
          </>
        ) : (
          <p
            className="color-black font-light font-sm"
            style={{
              textAlign: 'center',
              lineHeight: 'normal',
            }}
          >
            {t('paragraph.pr40')}
          </p>
        )}
      </div>

      <p className="color-black font-bold font-lg">{t('title.welcome-to-tng')}</p>
      <div className={styles.discoverList}>
        <div className={styles.item}>
          <DiscoverCard
            text={t('paragraph.pr41')}
            backgroundUrl="/images/support-background/bg-02.png"
          />
        </div>
        <div className={styles.item}>
          <DiscoverCard
            text={t('paragraph.pr42')}
            backgroundUrl="/images/support-background/bg-01.png"
          />
        </div>
      </div>
      <div className={styles.controller}>
        <SecondaryButton
          text={t('button.go-to-app')}
          variant="high-light-blue"
          style={{
            width: '100%',
          }}
        />
      </div>
    </div>
  );
}
