/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect, useRef } from 'react';
import styles from './styles.module.scss';
import SvgIcon from '../SVGIcon';
import { BottomPanel } from 'components/panel';
import { Button, FloatingPanelRef, Radio } from 'antd-mobile';
import { useDisclosure } from 'hooks';
import { formatVPPayReturnCard, showData } from 'utils';
import { SavedCardType } from 'types/payment.type';
import { BANK_INFO } from 'constant/bank';
import { isNull, keyBy } from 'lodash';
import { useTranslation } from 'react-i18next';
import { PAYMENT_METHOD } from 'enum/payment.enum';

type Props = {
  selectedMethodId: number;
  selectedCardId: number | null;
  isInitOpen?: boolean;
  listCard: SavedCardType[];
  onAddNewCard?: () => void;
  onChangePaymentMethod: (methodId: number) => void;
  onChangeCard: (cardId: number | null) => void;
};

const PAYMENT_METHODS = [
  {
    id: PAYMENT_METHOD.QR,
    name: 'internet-banking',
    iconPath: '/images/icon/qr-code.svg',
  },
  {
    id: PAYMENT_METHOD.CARD,
    name: 'credit-debit-card',
    iconPath: '/images/icon/debit-card.svg',
  },
  {
    id: PAYMENT_METHOD.CASH,
    name: 'cash',
    iconPath: '/images/icon/cash.svg',
  },
];

function PaymentMethodSelector({
  listCard = [],
  selectedMethodId,
  selectedCardId,
  onAddNewCard,
  onChangePaymentMethod,
  onChangeCard,
}: Props) {
  const { t } = useTranslation();
  const { isOpen, onClose, onOpen } = useDisclosure();
  const ref = useRef<FloatingPanelRef>();
  const bankKeyMapping = keyBy(BANK_INFO, 'code');
  const paymentMethodMapping = keyBy(PAYMENT_METHODS, 'id');
  const savedCardMapping = keyBy(listCard, 'id');

  const handleChangeMethod = (methodId: number) => () => {
    onChangePaymentMethod(methodId);
  };

  const handleChangeCard = (cardId: number | null) => () => {
    if (!isNull(cardId)) {
      onChangePaymentMethod(PAYMENT_METHOD.CARD);
    }
    onChangeCard(cardId);
  };

  const handleOpenBottom = () => {
    ref.current && ref.current.setHeight(window.innerHeight * 0.6);
  };

  const handleCloseBottom = () => {
    ref.current && ref.current.setHeight(0);
    if (isNull(selectedCardId) && selectedMethodId === PAYMENT_METHOD.CARD) {
      onChangePaymentMethod(PAYMENT_METHOD.QR);
    }
  };

  useEffect(() => {
    if (selectedMethodId === PAYMENT_METHOD.CARD && listCard.length > 0) {
      onChangeCard(Number(listCard[0].id));
    } else {
      onChangeCard(null);
    }
  }, [selectedMethodId]);

  useEffect(() => {
    if (isOpen) {
      handleOpenBottom();
    } else {
      handleCloseBottom();
    }
  }, [isOpen]);

  return (
    <div className={styles.wrapper}>
      <div className={styles.selected}>
        <div className={styles.itemCard}>
          <SvgIcon
            pathFill={'var(--primary-color)'}
            src={paymentMethodMapping[selectedMethodId].iconPath}
          />
          <p className="color-blue font-md font-medium">
            {t(`title.${paymentMethodMapping[selectedMethodId].name}`)}
          </p>
        </div>
        {!isNull(selectedCardId) && (
          <p className="color-blue font-md">
            {showData(formatVPPayReturnCard(savedCardMapping[selectedCardId].cardNumber))}
          </p>
        )}
        <div className={styles.checkIcon}>
          <SvgIcon
            src="/images/icon/check.svg"
            pathFill="var(--white-text-color)"
            width="12px"
            height="10px"
          />
        </div>
      </div>
      <p
        className={`color-orange font-md font-medium ${styles.link}`}
        style={{
          textAlign: 'center',
          marginTop: '12px',
        }}
        onClick={onOpen}
      >
        {t('title.other-payment-method')}
      </p>
      <BottomPanel refPanel={ref} anchors={[0, window.innerHeight * 0.6]} onClose={onClose}>
        <div className={styles.panelBody}>
          <p
            className="font-lg color-black font-bold"
            style={{
              textAlign: 'center',
            }}
          >
            {t('title.payment-method')}
          </p>
          <div className={styles.radioGroup}>
            {PAYMENT_METHODS.map((method) => {
              return (
                <div key={method.id} className={styles.methodBlock}>
                  <div
                    className={`${styles.method} ${
                      method.id === selectedMethodId ? styles.active : ''
                    }`}
                    onClick={handleChangeMethod(method.id)}
                  >
                    <div className={styles.info}>
                      <SvgIcon
                        src={method.iconPath}
                        pathFill={'var(--white-text-color)'}
                        style={{
                          display: method.id === selectedMethodId ? 'block' : 'none',
                        }}
                      />
                      <SvgIcon
                        src={method.iconPath}
                        pathFill={'var(--primary-color)'}
                        style={{
                          display: method.id === selectedMethodId ? 'none' : 'block',
                        }}
                      />
                      <p className="color-blue font-md font-medium">{t(`title.${method.name}`)}</p>
                    </div>
                    <Radio
                      className={styles.ratio}
                      checked={method.id === selectedMethodId}
                      style={{
                        '--icon-size': '16px',
                      }}
                    />
                  </div>
                  {selectedMethodId === PAYMENT_METHOD.CARD &&
                    method.id === PAYMENT_METHOD.CARD && (
                      <div className={styles.debitCards}>
                        <div className={styles.listItem}>
                          {listCard?.map((card) => {
                            return (
                              <div
                                key={card?.id}
                                className={styles.item}
                                onClick={handleChangeCard(Number(card.id))}
                              >
                                <div className={styles.leftInfo}>
                                  {bankKeyMapping[card.bankCode]?.logo ? (
                                    <img
                                      className={styles.bankIcon}
                                      src={bankKeyMapping[card.bankCode]?.logo}
                                    />
                                  ) : (
                                    <p className="font-md font-black">{showData(card.bankCode)}</p>
                                  )}
                                </div>
                                <div className={styles.rightInfo}>
                                  <p>{showData(formatVPPayReturnCard(card.cardNumber))}</p>
                                  {selectedCardId == card.id && (
                                    <SvgIcon src="/images/icon/check.svg" />
                                  )}
                                </div>
                              </div>
                            );
                          })}
                        </div>
                        <Button className={styles.addCard} onClick={onAddNewCard}>
                          <SvgIcon src="/images/icon/dashed-plus-icon.svg" />{' '}
                          {t('button.add-new-card')}
                        </Button>
                      </div>
                    )}
                </div>
              );
            })}
          </div>
        </div>
      </BottomPanel>
    </div>
  );
}

export default React.memo(PaymentMethodSelector);
