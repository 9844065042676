import { Step } from 'antd-mobile/es/components/steps/step';
import styles from './styles.module.scss';
import { Steps } from 'antd-mobile';

type Props = {
  step?: number;
};

export default function HeaderStep({ step }: Props) {
  return (
    <div className={styles.container}>
      <div className={styles.headerWrapper}>
        <div className={styles.logo}>
          <img src="/images/logo/logo-new-gym.png" />
        </div>
        <div className={styles.action}>
          <Steps current={step}>
            <Step className={styles.stepItem} icon={<span>1</span>} />
            <Step className={styles.stepItem} icon={<span>2</span>} />
            <Step className={styles.stepItem} icon={<span>3</span>} />
            <Step className={styles.stepItem} icon={<span>4</span>} />
          </Steps>
        </div>
      </div>
    </div>
  );
}
