import { PrimaryButton } from 'components';
import styles from './styles.module.scss';
import { SpaceProps } from 'antd-mobile';

type Props = {};

export default function Header({ ...rest }: Props & SpaceProps) {
  return (
    <div className={styles.container}>
      <div className={styles.headerWrapper} {...rest}>
        <div className={styles.logo}>
          <img src="/images/logo/logo-new-gym.png" />
        </div>
        <div className={styles.action}>
          <PrimaryButton text="Join us" variant="high-light" />
        </div>
      </div>
    </div>
  );
}
