import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import english from 'locales/en.json';
import vietnamese from 'locales/vi.json';
import LanguageDetector from 'i18next-browser-languagedetector';
import XHR from 'i18next-http-backend'; // <---- add this
import { LANGUAGE } from 'enum';

const resources = {
  en: {
    translation: english,
  },
  vi: {
    translation: vietnamese,
  },
  'vi-VN': {
    translation: vietnamese,
  },
};

i18n
  .use(XHR)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    fallbackLng: LANGUAGE.EN,

    // lng: LANGUAGE.VN,
    resources,
    detection: {
      order: ['navigator', 'htmlTag', 'cookie'],
      caches: [],
    },
    interpolation: {
      escapeValue: false, // react already safes from xss
    },
  });

export default i18n;
