import React from 'react';
import styles from './styles.module.scss';
import { Divider, Skeleton } from 'antd-mobile';
import { SecondaryButton } from 'components/button';
import { useDisclosure } from 'hooks';
import { SvgIcon, TooltipWrapper } from 'components/shared';
import { formatPrice } from 'utils';
import { useTranslation } from 'react-i18next';

type Props = {
  isBlackCard?: boolean;
  isSkeleton?: boolean;
  mainInfo?: {
    planName: string;
    price: number;
    duration: number;
    unit: string;
    slogan: string;
    description: string;
    tagName?: string;
  };
  basicInfos?: {
    content: string;
    iconPath: string;
    hint?: {
      title: string;
      content: string;
    };
  }[];
  moreInfos: {
    icon: string;
    content: string;
  }[];
  onSelect?: () => void;
};

function PriorityMembershipPlan({
  isBlackCard = false,
  isSkeleton = false,
  mainInfo,
  basicInfos,
  moreInfos,
  onSelect,
}: Props) {
  const { t } = useTranslation();
  const { isOpen, onToggle } = useDisclosure();

  return isSkeleton ? (
    <Skeleton
      animated
      className={styles.cardWrapperSkeleton}
      style={{
        height: isBlackCard ? '578px' : '508px',
      }}
    />
  ) : (
    <div
      className={styles.cardWrapper}
      style={{
        background: isBlackCard
          ? 'linear-gradient(173deg, #006a81 0.5%, #0087a4 41.71%, #00bfe9 99.74%)'
          : 'var(--white-text-color)',
        border: isBlackCard ? 'none' : '1px solid var(--secondary-text-color)',
      }}
    >
      <div className={styles.cardHeader}>
        <p
          className={styles.planName}
          style={{
            color: isBlackCard ? 'var(--white-text-color)' : 'var(--primary-text-color)',
          }}
        >
          {mainInfo?.planName}
        </p>
        {mainInfo?.tagName && <p className={styles.tag}>{t(mainInfo?.tagName)}</p>}
      </div>
      <div className={styles.cardPrice}>
        <div className={styles.price}>
          <p
            className={styles.value}
            style={{
              color: isBlackCard ? 'var(--sub-high-light-color)' : 'var(--primary-text-color)',
            }}
          >
            {formatPrice(mainInfo?.price || 0)}
            <span
              className={styles.duration}
              style={{
                color: isBlackCard ? 'var(--white-text-color)' : 'var(--primary-text-color)',
              }}
            >
              / {mainInfo?.duration} {t(mainInfo?.unit || '')}
            </span>
          </p>
        </div>
      </div>
      <div className={styles.cardInfo}>
        <div className={styles.commitment}>
          <SvgIcon
            pathFill={isBlackCard ? 'var(--sub-high-light-color)' : 'var(--primary-color)'}
            src="/images/icon/like-green.svg"
          />
          <p
            className="font-lg color-green font-light"
            style={{
              color: isBlackCard ? 'var(--sub-high-light-color)' : 'var(--primary-color)',
            }}
          >
            {t(mainInfo?.slogan || '')}
          </p>
        </div>
        <p
          className="font-lg font-light"
          style={{
            lineHeight: 'normal',
            color: isBlackCard ? 'var(--white-text-color)' : 'var(--secondary-text-color)',
          }}
        >
          {t(mainInfo?.description || '')}
        </p>
        <Divider
          style={{
            margin: '24px 0',
          }}
        />
        <div className={styles.mainInfo}>
          {basicInfos?.map((info, idx) => {
            return (
              <div key={idx} className={styles.infoItem}>
                <SvgIcon
                  pathFill={isBlackCard ? 'var(--white-text-color)' : 'var(--secondary-text-color)'}
                  src={info.iconPath}
                />
                <p
                  style={{
                    color: isBlackCard ? 'var(--white-text-color)' : 'var(--secondary-text-color)',
                    flexShrink: '20',
                  }}
                >
                  {t(info.content)}
                </p>
                {info.hint && (
                  <TooltipWrapper
                    className={styles.tooltip}
                    tooltipTitle={t(info.hint?.title)}
                    tooltipContent={t(info.hint?.content)}
                  >
                    <SvgIcon
                      pathFill={
                        isBlackCard ? 'var(--white-text-color)' : 'var(--secondary-text-color)'
                      }
                      src="/images/icon/more-info.svg"
                    />
                  </TooltipWrapper>
                )}
              </div>
            );
          })}
        </div>
        <Divider
          style={{
            margin: '24px 0',
          }}
        />
        <div
          className={styles.extendInfo}
          style={{
            maxHeight: isOpen ? '100vh' : '0px',
          }}
        >
          <p
            className="font-lg font-bold"
            style={{
              color: isBlackCard ? 'var(--white-text-color)' : 'var(--primary-text-color)',
            }}
          >
            {t('title.what-you-get')}
          </p>

          <div className={styles.listItem}>
            {moreInfos.map((item, idx) => {
              return (
                <div key={idx} className={styles.item}>
                  <SvgIcon
                    pathFill={isBlackCard ? 'var(--white-text-color)' : 'var(--primary-color)'}
                    src={item.icon}
                  />
                  <p
                    className="font-md font-light"
                    style={{
                      color: isBlackCard
                        ? 'var(--white-text-color)'
                        : 'var(--secondary-text-color)',
                      flexShrink: '20',
                    }}
                  >
                    {t(item.content)}
                  </p>
                </div>
              );
            })}
          </div>
          <Divider
            style={{
              margin: '24px 0',
            }}
          />
        </div>
      </div>
      <div className={styles.controller}>
        <div className={styles.moreDetail} onClick={onToggle}>
          <p
            className="font-md color-white font-medium"
            style={{
              color: isBlackCard ? 'var(--white-text-color)' : 'var(--primary-color)',
            }}
          >
            {t('button.see-offer-detail')}
          </p>
          <SvgIcon
            pathFill={isBlackCard ? 'var(--white-text-color)' : 'var(--primary-color)'}
            src="/images/icon/chevron-down.svg"
            style={{
              transform: `rotate(${isOpen ? 180 : 0}deg)`,
            }}
          />
        </div>
        <SecondaryButton
          text={t('button.select')}
          variant={isBlackCard ? 'solid' : 'high-light-blue'}
          onClick={onSelect}
        />
      </div>
    </div>
  );
}

export default React.memo(PriorityMembershipPlan);
