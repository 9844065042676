import { PasscodeInputRef } from 'antd-mobile';
import styles from './styles.module.scss';
import { PinInput } from 'components/input';
import { ROUTES } from 'constant';
import { useDirection } from 'hooks';
import { AuthService } from 'services';
import { useEffect, useRef } from 'react';
import { useDispatch } from 'stores';
import { useLocation, useParams } from 'react-router';
import { setLoadingToast } from 'stores/common';
import { VerifyOTPLoginRes } from 'types';
import { delayNavigate, isSuccessCode, LocalStorage, STORAGE_KEY } from 'utils';
import { useTranslation } from 'react-i18next';

const Storage = new LocalStorage();

export default function VerifyOtpContainer() {
  const authService = new AuthService();
  const dispatch = useDispatch();
  const { goTo } = useDirection();
  const { t } = useTranslation();
  const location = useLocation();
  const inputCodeRef = useRef<PasscodeInputRef>(null);
  const { clubId, membershipId } = useParams();

  const handleComplete = async (value: string) => {
    if (inputCodeRef.current) inputCodeRef.current.blur();
    dispatch(setLoadingToast(true));
    const response = await authService.verifyOTPLogin({
      otpCode: value,
      phoneNumber: location.state ? location.state?.phoneNumber : '',
    });
    const { code, data } = response;
    const dataRes = data as VerifyOTPLoginRes;

    if (isSuccessCode(code)) {
      Storage.setStorageItem(STORAGE_KEY.ACCESS_TOKEN, dataRes.accessToken);
      Storage.setStorageItem(STORAGE_KEY.ACCOUNT_ID, dataRes.accountId);
      delayNavigate(
        goTo(
          ROUTES.PAYMENT.replace(':clubId', String(clubId)).replace(
            ':membershipId',
            String(membershipId),
          ),
        ),
      );
    }
  };

  useEffect(() => {
    if (inputCodeRef.current) inputCodeRef.current.focus();
  }, []);

  return (
    <div className={styles.wrapper}>
      <div className={styles.form}>
        <p
          className={`font-lg color-black font-light ${styles.label}`}
          style={{
            whiteSpace: 'pre-wrap',
          }}
        >
          {t('paragraph.pr35')}
          <span className="color-blue font-bold">{`\n${
            location.state ? location.state?.phoneNumber : ''
          } `}</span>
          {t('paragraph.pr36')}
        </p>
        <div className={styles.input}>
          <PinInput onComplete={handleComplete} />
        </div>
      </div>
    </div>
  );
}
