import React from 'react';
import styles from './styles.module.scss';
import { Collapse } from 'antd-mobile';
import SvgIcon from '../SVGIcon';
import { useTranslation } from 'react-i18next';

const FAQS = [
  {
    title: 'paragraph.pr23',
    content: 'paragraph.pr24',
  },
  {
    title: 'paragraph.pr25',
    content: 'paragraph.pr26',
  },
  {
    title: 'paragraph.pr27',
    content: 'paragraph.pr28',
  },
  {
    title: 'paragraph.pr29',
    content: 'paragraph.pr30',
  },

  {
    title: 'paragraph.pr31',
    content: 'paragraph.pr32',
  },

  {
    title: 'paragraph.pr33',
    content: 'paragraph.pr34',
  },
];

function Faqs() {
  const { t } = useTranslation();

  return (
    <div className={styles.wrapper}>
      <p className="color-black font-bold font-xxl">{t('title.membership-faqs')}</p>
      <div className={styles.question}>
        <Collapse
          arrowIcon={(active) =>
            active ? (
              <SvgIcon pathFill="var(--primary-text-color)" src="/images/icon/minus.svg" />
            ) : (
              <SvgIcon pathFill="var(--primary-text-color)" src="/images/icon/plus.svg" />
            )
          }
        >
          {FAQS.map((faq, idx) => (
            <Collapse.Panel key={String(idx)} title={t(faq.title)}>
              {t(faq.content)}
            </Collapse.Panel>
          ))}
        </Collapse>
      </div>
    </div>
  );
}

export default React.memo(Faqs);
